
















import Vue from "vue";
import Component from "vue-class-component";
import SignInForm from "@/vue/components/SignInForm.vue";
import store from "@/store/store";
import { UserRole } from "@/model/Enums";

// https://vuejs.org/v2/guide/typescript.html

//
// -- computed properties
//

@Component({
    components: { SignInForm }
})
export default class HomeSignIn extends Vue {

    onAuthenticated() {
        if (store.state.signedInUser?.role == UserRole.Landlord || store.state.signedInUser?.role == UserRole.LandlordUser) {
            this.$router.push("/Buildings");
        } else if (store.state.signedInUser?.role == UserRole.Verifier) {
            this.$router.push("/Verifications");
        }
        
    }
}

